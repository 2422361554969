import {useRef, useState, useEffect} from 'react';
import './UserCard.css';
import {Avatar, CircularProgress, Popover} from '@material-ui/core';
import useGlobalState from '../../../util/useGlobalState';
import Instance from '../../../util/axios';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import MissingProfile from '../../../assets/Icons/MissingProfileIcon.png';
import getImage from '../../../util/getImage';

export default function UserCard({
    channel,
    popupOpen,
    activeChannel,
    setActiveChannel,
    setPopupOpen,
}) {
    const dotRef = useRef();
    const {state} = useGlobalState();
    const {user} = state;
    const [isDeleting, setIsDeleting] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [cardUser, setCardUser] = useState();

    useEffect(() => {
        getUserRating();
    }, [channel]);

    const getUserRating = async () => {
        try {
            const otherUser =
                (await channel.participants[0]) === user.id
                    ? channel.participants[1]
                    : channel.participants[0];
            const {data, status} = await Instance.get(`users/${otherUser}`);
            if (status !== 200) return;
            setCardUser(data);
        } catch (e) {
            // console.log(e)
        }
    };

    // const blockUser = async () => {
    //     const userList = [user.id, conversation.conversationWith.uid];
    //     try {
    //         setActiveChatUser(false);
    //         setMessages(null);
    //     } catch (e) {
    //         // console.log(e)
    //     }
    // };
    const renderLastMessage = () => {
        return channel?.lastMessage?.userId === user.id ? (
            <>
                <span className='LastMessageOwner'>You: </span>
                {!channel?.lastMessage?.text  && channel.lastMessage?.images?.length>0 && 
                <span>Sent an image</span>}
                <span>
                    {channel?.lastMessage?.text > 25
                        ? `${channel?.lastMessage?.text.slice(0, 25)}...`
                        : channel?.lastMessage?.text}
                </span>
            </>
        ) : (
            <>
                <span className='LastMessageOwner'>
                    {cardUser?.firstName}:{' '}
                </span>
                {!channel?.lastMessage?.text  && channel.lastMessage?.images?.length>0 && 
                <span>Sent an image</span>}
                <span>{channel?.lastMessage?.text.split(0, 20)}</span>
            </>
        );
    };

    const handleCardClick = () => {
        setActiveChannel(channel);
    };

    return (
        <>
            <div
                style={
                    isDeleting
                        ? {alignItems: 'center', justifyContent: 'center'}
                        : null
                }
                className='UserCard'
                onClick={handleCardClick}
            >
                {isDeleting ? (
                    <CircularProgress size={30} color={'secondary'} />
                ) : (
                    <>
                        <div className='UserCardContent'>
                            <div className='UserCardTop'>
                                <Avatar
                                    src={
                                        cardUser && cardUser.avatar
                                            ? getImage(cardUser.avatar)
                                            : MissingProfile
                                    }
                                    style={{height: 55, width: 55}}
                                ></Avatar>
                                <div className='UserCardDetails'>
                                    <div className='user-name'>
                                        {' '}
                                        <h6>{cardUser?.firstName}</h6>
                                    </div>
                                    <span>
                                        <b>Status:</b> Borrow Enquiry
                                    </span>
                                </div>
                            </div>
                            <div
                                className={`${
                                    channel?.lastMessage?.userId === user?.id ||
                                    channel?.lastMessage?.isRead ||
                                    activeChannel?.id ===
                                        channel?.lastMessage?.channelId
                                        ? ''
                                        : 'unread-message'
                                } UserCardBottom`}
                            >
                                <div>{renderLastMessage()}</div>
                            </div>
                        </div>
                        <div className='UserCardIconContainer'>
                            <div
                                ref={dotRef}
                                className='UserCardDotIconContainer'
                            >
                                <MoreHorizIcon
                                    onClick={e => {
                                        e.stopPropagation();
                                        setPopupOpen(channel.id);
                                        setAnchorEl(dotRef.current);
                                    }}
                                    style={{fill: '#b43b4c'}}
                                />
                            </div>
                            <Popover
                                style={{marginTop: 20}}
                                anchorEl={anchorEl}
                                onClose={e => {
                                    e.stopPropagation();
                                    setPopupOpen(null);
                                }}
                                open={
                                    popupOpen ? popupOpen === channel.id : false
                                }
                            >
                                <div
                                    className='UserCardBlockButton'
                                    // onClick={blockUser}
                                >
                                    <p>Block User</p>
                                </div>
                            </Popover>
                            <ChevronRightIcon
                                style={{fill: '#b43b4c', marginBottom: '0.5em'}}
                            />
                            {channel.unreadMessageCount > 0 && (
                                <span className={`userstatus`}>
                                    {channel.unreadMessageCount}
                                </span>
                            )}
                            {/* <div>
                                    {moment(lastMessage?.createdAt).isSame(
                                        Date.now(),
                                        'day',
                                    )
                                        ? moment(lastMessage?.createdAt).format(
                                              'HH:mm',
                                          )
                                        : moment(lastMessage?.createdAt).format(
                                              'DD MMM',
                                          )}
                                </div> */}
                        </div>
                    </>
                )}
            </div>
        </>
    );
}
