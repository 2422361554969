import ReactGA from "react-ga4";

// Initialize Google Analytics
const initializeGA = () => {
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID); // Replace with your Measurement ID
};

// Track Page Views
const trackPageView = (page) => {
  ReactGA.send({ hitType: "pageview", page });
};

// Track Events (e.g., Button Clicks, Downloads)
const trackEvent = (action, category, label) => {
  ReactGA.event({
    action,
    category,
    label,
  });
};

export { initializeGA, trackPageView, trackEvent };
