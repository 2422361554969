import React, {useState, useEffect, useReducer, createContext} from 'react';
import '../Marketing.css';
import './ContactUs.css';
import axios from 'axios';
import Instance from '../../../util/axios';
import NavBar from '../../../components/marketing/NavBar/NavBar';
import ContactTextInput from '../../../components/marketing/ContactTextInput/ContactTextInput';
import Footer from '../../../components/marketing/Footer/Footer';
import contactUsReducer from '../../../util/reducers/contactUsReducer';
import {async, validate} from 'validate.js';
import {contactUsDetailsConstraints} from '../../../util/validationConstraints';
import Button from '../../../components/Button/Button';
import useErrorState from '../../../util/reducers/errorContext';
import {SNACKBAR_BUTTON_TYPES} from '../../../assets/Data/LBSEnum';
import {MESSAGES} from '../../../util/Message';

const ContactUs = () => {
    const [errorMessages, setErrorMessages] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [apiError, setApiError] = useState('');
    const {errorDispatch} = useErrorState();

    const [state, dispatch] = useReducer(contactUsReducer, {
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        message: '',
        isSubscribe: false,
    });

    const {firstName, lastName, email, phoneNumber, message, isSubscribe} =
        state;

    const validateInputs = () => {
        const validationErrors = validate(
            {firstName, lastName, email, phoneNumber, message},
            contactUsDetailsConstraints,
        );

        if (validationErrors) {
            setErrorMessages(validationErrors);
            return false;
        }
        setErrorMessages({});
        return true;
    };

    const getErrorMessage = inputName => {
        if (Object.keys(errorMessages).length === 0) return null;
        for (const key in errorMessages) {
            if (Object.keys(errorMessages)[0] === inputName)
                return errorMessages[key][0];
        }
    };
    const clearState = () => {
        dispatch({type: 'setLastName', data: ''});
        dispatch({type: 'setFirstName', data: ''});
        dispatch({type: 'setEmail', data: ''});
        dispatch({type: 'setPhoneNumber', data: ''});
        dispatch({type: 'setIsSubscribe', data: false});
        dispatch({type: 'setMessage', data: ''});
    };

    const notify = message => {
        errorDispatch({
            type: 'openSnackBar',
            data: {
                message,
                btnText: SNACKBAR_BUTTON_TYPES.CLOSE,
                btnFunc: () => errorDispatch({type: 'closeSnackBar'}),
            },
        });
    };

    const submitDetails = async () => {
        try {
            setIsLoading(true);
            setApiError('');
            const result = await Instance.post('/contact_us/create', state);
            if (result?.data?.status !== 201) {
                if (result?.data?.title === 'Invalid Resource') {
                    notify(result?.data?.detail);
                    return false;
                }
            }
            clearState();
            notify(MESSAGES.SUCCESS_CONTACT_US);
            return result.data;
        } catch (error) {
            setIsLoading(false);
            setApiError('Unable to proceed please check email!');
            if (error && axios.isAxiosError(error)) {
                if (
                    error?.code === 'ERR_NETWORK' ||
                    error?.code === 'ECONNABORTED'
                )
                    throw Error(
                        'There was an error with your connection, please try again',
                    );
            }
            throw Error('Unable to proceed');
        } finally {
            setIsLoading(false);
        }
    };
    const handleSubmit = async () => {
        const valid = validateInputs();
        if (!valid) return;
        await submitDetails();
    };

    return (
        <div className='marketing_container'>
            <NavBar />

            <div className='marketing_img_md_container bg_contact_us'>
                <div className='main_title_section protection_title_position'>
                    <p className='main_title'>Get In Touch</p>
                </div>
            </div>

            <div className='marketing_img_flexible_container bg_grey flex_box center_items straight_column'>
                <p
                    className='contact_detail_title form_data'
                    // style={{ maxWidth: '70%', padding: '0', paddingTop: '4rem' }}
                >
                    We’re here to help! If you haven’t found what you’re looking
                    for in our Guides and FAQs or Blog feel free to fill out the
                    form below with your query and we’ll get back to you soon.
                </p>
            </div>

            <div className='marketing_img_md_container bg_grey flex_box center_items responsive_contact_container'>
                <div className='image_half_container contact_img_bg'></div>
                <div className='contact_input_container'>
                    <div className='contact_input_box'>
                        <div className='flex_box contact_box_responsive'>
                            <div className='input_fields_left'>
                                <ContactTextInput
                                    label='First Name'
                                    inputValue={firstName}
                                    setInput={value =>
                                        dispatch({
                                            type: 'setFirstName',
                                            data: value,
                                        })
                                    }
                                    errorMessage={getErrorMessage('firstName')}
                                />
                                <ContactTextInput
                                    label='Last Name'
                                    inputValue={lastName}
                                    setInput={value =>
                                        dispatch({
                                            type: 'setLastName',
                                            data: value,
                                        })
                                    }
                                    errorMessage={getErrorMessage('lastName')}
                                />
                                <ContactTextInput
                                    label='Email'
                                    inputValue={email}
                                    setInput={value =>
                                        dispatch({
                                            type: 'setEmail',
                                            data: value,
                                        })
                                    }
                                    errorMessage={getErrorMessage('email')}
                                />
                                <ContactTextInput
                                    label='Phone Number'
                                    type='number'
                                    inputValue={phoneNumber}
                                    setInput={value =>
                                        dispatch({
                                            type: 'setPhoneNumber',
                                            data: value,
                                        })
                                    }
                                    errorMessage={getErrorMessage(
                                        'phoneNumber',
                                    )}
                                />
                            </div>
                            <div className='input_fields_right'>
                                <ContactTextInput
                                    label='Your Message'
                                    inputValue={message}
                                    setInput={value =>
                                        dispatch({
                                            type: 'setMessage',
                                            data: value,
                                        })
                                    }
                                    errorMessage={getErrorMessage('message')}
                                />
                                <div className='news_letter_box pt-3'>
                                    <div className='news_title_box'>
                                        <p className='news_main_title'>
                                            Subscribe to our e-news
                                        </p>
                                        <p className='news_sub_title'>
                                            Subscribe to our Little Big Shed
                                            email updates.
                                        </p>
                                    </div>
                                    <div className='news_checkbox_container'>
                                        <input
                                            type='checkbox'
                                            className='news_checkbox_input'
                                            checked={isSubscribe}
                                            onClick={() =>
                                                dispatch({
                                                    type: 'setIsSubscribe',
                                                    data: !isSubscribe,
                                                })
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='submit_btn_container'>
                            <Button
                                text='Send Message'
                                isLoading={isLoading}
                                errorMessage={apiError}
                                onClick={e => handleSubmit(e)}
                            />
                        </div>
                    </div>
                    <div className='contact_details_box'>
                        <div className='contact_number_box'>
                            <p className='contact_detail_title'>
                                Call Our Help Line
                            </p>
                            <p className='contact_detail_content'>0800 1234</p>
                        </div>
                        <div className='contact_email_box'>
                            <p className='contact_detail_title'>
                                Email Our Support Center
                            </p>
                            <p className='contact_detail_content'>
                                <a href='mailto:support@littlebigshed.co.nz'>
                                    support@littlebigshed.co.nz
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    );
};

export default ContactUs;
