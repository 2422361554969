import { Message } from '../types/Chat';
import Instance from '../util/axios';


export const getMessage = async (id: string) => {
    const { data } = await Instance.get(`/messages/${id}`);
    return data;
}

export const getMessagesByChannel = async (channelId:string) => {
    const {data} = await Instance.get(`/messages/channel/${channelId}?offset=0&limit=1000`);
    return data.data;
}

export const createMessage = async (message: Message) => {
    const { data } = await Instance.post('/messages', message);
    return data;
}

export const updateMessage = async (id: string, message: any) => {
    const { data } = await Instance.put(`/messages/${id}`, message);
    return data;
}

export const deleteMessage = async (id: string) => {
    const { data } = await Instance.delete(`/messages/${id}`);
    return data;
}