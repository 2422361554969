import '../../pages/item/item.css';
import Location from '../../assets/Icons/LocationIcon.svg';
// import Delivery from '../../assets/Icons/DeliveryIcon.svg';
import Category from '../../assets/Icons/CategoriesIcon.svg';
import COD from '../../assets/Icons/COD.svg';

import {ReactComponent as Profile} from '../../assets/Icons/UserCircle.svg';
import Calendar from '../../assets/Icons/HangingCalendar.svg';
import StarFilled from '../../assets/Icons/StarFilled';
import StarOutline from '../../assets/Icons/StarOutline';
import useGlobalState from '../../util/useGlobalState';
import {useHistory, useParams} from 'react-router-dom';
import Instance from '../../util/axios';
import Messages from '../../assets/Icons/ChatMessages.svg';
import AddCardModel from '../modals/AddBankModel/AddCardModel';
import {useState} from 'react';

const ItemInfoSection = ({
    item,
    openAvailabilityModal,
    favourited,
    getItemLikedByUser,
    setColdChatModalVisible,
}) => {
    const {user} = useGlobalState()?.state;
    const params = useParams();
    const history = useHistory();
    const [addCardOpen, setAddCardOpen] = useState(false);

    const handleFavourite = async () => {
        try {
            if (!favourited) {
                const {status} = await Instance.post('/likes/', {
                    itemId: item.id,
                });
                if (status !== 201) return;
                getItemLikedByUser(item.id);
            } else {
                const {status} = await Instance.delete(`/likes/${favourited}`);
                if (status !== 200) return;
                getItemLikedByUser(item.id);
            }
        } catch (error) {
            // console.log(error.response)
        }
    };

    const handleClickApply = () => {
        if (!item.price || user?.stripe?.customerId || item.isCOD) {
            history.push(`/item/${params.itemId}/application`);
        } else {
            !user ? history.push(`/login`) : setAddCardOpen(true);
        }
    };

    return (
        <>
            <div className='ItemName'>{item?.title}</div>

            <div className='ItemPriceFlex'>
                <div className='ItemPriceTextBig'>
                    {item?.price ? (
                        <>
                            ${item?.price}
                            <div className='ItemPerSlotText'>/ Slot</div>
                        </>
                    ) : (
                        'Free'
                    )}
                </div>
            </div>

            {item?.discount > 0 && (
                <div className='ItemDiscountText'>
                    {item?.discount}% off peak discount
                </div>
            )}

            <div
                className='LocationDeliveryCategory'
                style={{marginTop: '0.6rem'}}
            >
                <div className='LDCIconContainer'>
                    <img src={Location} alt='location' className='LDCIcon' />
                </div>
                {item?.address.suburb}
            </div>

            {/* <div className='LocationDeliveryCategory'>
                <div className='LDCIconContainer'>
                    <img
                        src={Delivery}
                        alt='delivary'
                        className='LDCIcon'
                        style={{height: '22px'}}
                    />
                </div>
                {item?.deliveryOption === 'BOTH' ||
                item?.deliveryOption === 'DELIVERY'
                    ? 'Delivery Available'
                    : 'Delivery Not Available'}
                &nbsp;

                <span
                    className={`DeliveryFeeText ${
                        item.deliveryOption === 'BOTH' ||
                        item.deliveryOption === 'DELIVERY'
                            ? ''
                            : 'Hide'
                    }`}
                >
                    / &nbsp;${item.deliveryPrice} Delivery Fee
                </span>
            </div>

            <div className='LocationDeliveryCategory'>
                <div className='LDCIconContainer'>
                    <img
                        src={Delivery}
                        alt='delivary'
                        className='LDCIcon'
                        style={{height: '22px'}}
                    />
                </div>
                {item.deliveryOption === 'BOTH' ||
                item.deliveryOption === 'PICKUP'
                    ? 'Pickup Available'
                    : 'Pickup Not Available'}
                &nbsp;
                <span
                    className={`DeliveryFeeText ${
                        item.deliveryOption === 'BOTH' ||
                        item.deliveryOption === 'PICKUP'
                            ? ''
                            : 'Hide'
                    }`}
                >
                    / &nbsp;${item.pickupPrice} Pickup Fee
                </span>
            </div> */}

            <div className='LocationDeliveryCategory'>
                <div className='LDCIconContainer'>
                    <img src={Category} alt='category' className='LDCIcon' />
                </div>
                {item.category}
            </div>
            <div className='LocationDeliveryCategory'>
                <div className='LDCIconContainer'>
                    <img src={COD} alt='category' className='LDCIcon' />
                </div>
                {item?.isCOD ? 'Pay online/Cash' : 'Pay Online'}
            </div>
            {user && user.id === item.userId ? (
                <button
                    className='editButton'
                    onClick={() => history.push(`/item/edit/${item.id}`)}
                >
                    Edit Item Details
                </button>
            ) : (
                <div>
                    <div className='ItemButtons'>
                        <button
                            className='ButtonAvailability'
                            onClick={openAvailabilityModal}
                        >
                            <div className='ItemButtonFlex'>
                                <img
                                    src={Calendar}
                                    alt='calendar'
                                    style={{marginRight: '0.5em'}}
                                />
                                Availability
                            </div>
                        </button>
                        <button
                            onClick={handleClickApply}
                            className='ButtonApply'
                            disabled={item?.isPaused}
                        >
                            <div className='ItemButtonFlex'>
                                <Profile fill='#ffffff' height={23} />
                                {item?.isPaused ? 'Not Available' : 'Apply Now'}
                            </div>
                        </button>
                    </div>
                    <div className='ItemButtons'>
                        <button
                            className='ButtonColdChat'
                            disabled={!user}
                            onClick={() =>
                                user
                                    ? setColdChatModalVisible(true)
                                    : history.push(`/login`)
                            }
                        >
                            <div className='ChatStartButtonFlex'>
                                <img
                                    src={Messages}
                                    alt='calendar'
                                    style={{
                                        marginRight: '1em',
                                        fill: 'white',
                                    }}
                                />
                                Start Chat
                            </div>
                        </button>
                        <button
                            className='ButtonFavourite'
                            onClick={handleFavourite}
                            // style={{ padding: '.5em 1em' }}
                            disabled={!user}
                        >
                            {favourited ? (
                                <StarFilled fill='#ffffff' />
                            ) : (
                                <StarOutline fill='#ffffff' />
                            )}
                        </button>
                    </div>
                </div>
            )}

            <div className='description-box'>
                <div className='ItemDetailsHeader'>Description</div>
                <div>{item.description}</div>
            </div>
            <div className='specification-box'>
                <div className='ItemDetailsHeader'>Item Details</div>
                {item?.specification && Object.keys(item.specification)?.length > 0 ? Object.keys(item.specification).map((dt,index) => {
                    return (
                        <div key={index} className='ItemSpecificationFlex'>
                            <div >{dt}</div>
                            <p>{item.specification[dt]}</p>
                        </div>
                    );
                }):
                <div className='ItemButtonFlex' > No specification added !</div>
            }
            </div>
            <AddCardModel
                addCardOpen={addCardOpen}
                setAddCardOpen={setAddCardOpen}
                addcardFunction={() => {
                    setAddCardOpen(false);
                    history.push({
                        pathname: `/user/account`,
                    });
                }}
            />
        </>
    );
};

export default ItemInfoSection;
