import useGlobalState from '../../../util/useGlobalState';
import './EnquiryMessage.css';
import moment from 'moment';

export default function EnquiryMessage({messageObj}) {
    const {state} = useGlobalState();
    const {user} = state;
    const renderEnquiry = () => {
        if (messageObj.metadata?.enquiry) {
            return messageObj.userId === user.id ? (
                // User has enquired about someone else's item
                //  `You enquired about ${messageObj.receiver.name}'s ${messageObj.data.metadata.itemName}`
                <>
                    <span className='EnquiryMessageText'>You </span>
                    <span className='EnquiryMessageText'>enquired about </span>
                    <span className='EnquiryMessageBold'>
                        {messageObj?.metadata?.ownerName}'s
                    </span>
                    <span className='EnquiryMessageText'>
                        {' '}
                        {messageObj.metadata.itemName}
                    </span>
                    {messageObj.metadata?.bookingDate && (
                        <span className='EnquiryMessageText'>
                            {' '}
                            At{' '}
                            {moment(
                                messageObj.metadata?.bookingDate,
                            ).format('YYYY-MM-DD hh:mm A')}
                        </span>
                    )}
                </>
            ) : (
                // Someone has enquired about the user's item
                <>
                    <span className='EnquiryMessageBold'>
                        {messageObj.user.firstName}{' '}{messageObj.user.lastName}{' '}
                    </span>
                    <span className='EnquiryMessageText'>enquired about </span>
                    <span className='EnquiryMessageText'>your </span>
                    <span className='EnquiryMessageText'>
                        {' '}
                        {messageObj.metadata.itemName}
                    </span>
                    {messageObj.metadata?.bookingDate && (
                        <span className='EnquiryMessageText'>
                            {' '}
                            At{' '}
                            {moment(
                                messageObj.metadata?.bookingDate,
                            ).format('YYYY-MM-DD hh:mm A')}
                        </span>
                    )}
                </>
            );
        }
    };
    return (
        <div className='EnquiryMessageContainer'>
            <p>{renderEnquiry()}</p>
        </div>
    );
}
