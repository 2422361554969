import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React, { useEffect, useReducer, useState } from 'react';
import { Redirect, Route, BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import LBSSnackBar from './components/LBSSnackBar/LBSSnackBar';
import CancellationPolicy from './components/marketing/Footer/CancellationPolicy';
import DamagesAndDisputes from './components/marketing/Footer/DamagesAndDisputes';
import PrivacyPolicy from './components/marketing/Footer/PrivacyPolicy';
import RentalAgreement from './components/marketing/Footer/RentalAgreement';
import { fetchToken, onMessageListener } from './firebase';
import ForgotPassword from './pages/ForgotPassword/ForgotPassword';
import UpdatePassword from './pages/account/UpdatePassword/UpdatePassword';
import UpgradeLender from './pages/account/UpgradeLender/UpgradeLender';
import AccountPage from './pages/account/account.js';
import Application from './pages/application/Application';
import EditItemPage from './pages/editItem/EditItemPage';
import ExtendBookingApplication from './pages/extension/ExtendBookingApplication';
import FavouritesPage from './pages/favourites/favourites.js';
import Home from './pages/home/home.js';
import ItemPage from './pages/item/item.js';
import LenderShed from './pages/lender-shed/lender-shed';
import Login from './pages/login/login';
import ContactUs from './pages/marketing/ContactUs/ContactUs';
import LendYourStuff from './pages/marketing/LendYourStuff/LendYourStuff';
import RentStuff from './pages/marketing/RentStuff/RentStuff';
import Messages from './pages/messages/Messages.js';
import Notifications from './pages/notifications/Notifications.js';
import PostItem from './pages/postitem/postitem';
import RegisterPage from './pages/register/register.js';
import Search from './pages/search/search';
import TradesPage from './pages/trades/trades';
import UnSubscribe from './pages/unSubscribeUser/UnSubScribePage';
import YourshedPage from './pages/yourshed/yourshed.js';
import ScrollToTop from './util/ScrollToTop';
import Instance from './util/axios';
import { notificationToast } from './util/notificationToaster';
import reducer from './util/reducers/globalStateReducer';
import snackbarReducer from './util/reducers/snackbarReducer';
import { getCurrentLatLong } from './util/locationPermission';
import socket from './socket';
import { getChannelsByUser } from './services/channel';
import { initializeGA, trackPageView } from './analytics';
import PageTracker from './PageTracker';

export const GlobalStateContext = React.createContext();
export const GlobalErrorContext = React.createContext();

const initialState = {};
const initialErrorState = {
    toggleSnackbar: false,
    snackbarMessage: '',
    snackbarBtnText: '',
    snackbarBtnFunc: () => { },
};

const stripe = loadStripe(process.env.REACT_APP_STRIPE_KEY);

function App() {
    const [state, dispatch] = useReducer(reducer, initialState);
    const [errorState, errorDispatch] = useReducer(
        snackbarReducer,
        initialErrorState,
    );
    const [loadingUser, setLoadingUser] = useState(true);
    const { user  ,unReadMessageCount } = state;
    const token = localStorage.getItem('LBSToken');
    const [channels ,setChannels] = useState([]);
    onMessageListener()
        .then(payload => {
            notificationToast(
                payload.notification.title,
                payload.notification.body,
            );
        })
        .catch(err => console.log('failed: ', err));

    let fcmToken = localStorage.getItem('fcmToken');

    useEffect(() => {
        if (!fcmToken) {
            fetchToken();
        }
    }, [fcmToken]);

    useEffect(() => {
        initializeGA(); // Initialize Google Analytics
        trackPageView(window.location.pathname); // Track initial page load
      }, []);

    const lendingRoute = path => {
        window.location.href = `${process.env.REACT_APP_LENDING_PAGE}${path}`;
    };
    useEffect(() => {
        if (window.location.pathname === '/') {
            lendingRoute('/');
        }
        // if(window.location.pathname !== "/"){
        //   window.location.href = '/'
        // }
    }, [window.location.pathname]);

    useEffect(() => {
        handleGetLocation();
        if (!token) {
            setLoadingUser(false);
            return;
        }
        getCurrentUser();
    }, []);

    useEffect(() => {
        if (user) {
            getUnReadMessageCount();
            getUnReadNotificationsCounts();
            fetchChannels()
        }
    }, [user]);

    const fetchChannels = async () => {
        const result = await getChannelsByUser(user?.id);
        setChannels(result.channels.map(dt=>dt.id));
    };

    useEffect(() => {
        // if(user){
            socket.on('chat', (data) => {
            const foundItem =  channels.includes(data.channelId);
                if(foundItem){
                    dispatch({
                        type: 'setUnReadMessageCount',
                        data: unReadMessageCount + 1,
                    });
                }
              });
          
              return () => {
                socket.off('chat');
                socket.removeListener('chat')
              }
        // }

      }, [channels]);

    const getCurrentUser = async () => {
        try {
            const { data } = await Instance.get('/users/me');
            dispatch({ type: 'setUser', data });
        } catch (err) {
            localStorage.removeItem('LBSToken');
        } finally {
            setLoadingUser(false);
        }
    };

    const handleGetLocation = async () => {
        try {
            const { latitude, longitude } = await getCurrentLatLong();
            dispatch({ type: 'setCurrentLatLong', data: { latitude, longitude } });


        } catch (err) {
            console.log('err', err);
        }
    };


    const getUnReadNotificationsCounts = async () => {
        try {
            const { data } = await Instance.get('/notification-history/counts');
            dispatch({
                type: 'setUnReadNotificationsCounts',
                data: data,
            });
        } catch (error) {
            // console.log({ error })
        }
    };

    const getUnReadMessageCount = async () => {
        try {
            const { data } = await Instance.get(`/messages/unread-message-count/${user.id}`);
            dispatch({
                type: 'setUnReadMessageCount',
                data: data,
            });
        } catch (error) {
            // console.log({ error })
        }
    };

    function AuthRoute({ component: Component, ...rest }) {
        return (
            <Route
                {...rest}
                render={props =>
                    user ? (
                        <Component {...props} />
                    ) : (
                        <Redirect
                            to={{
                                pathname: '/login',
                                state: { from: props.location },
                            }}
                        />
                    )
                }
            />
        );
    }

    function AuthRedirectRoute({ component: Component, ...rest }) {
        return (
            <Route
                {...rest}
                render={props =>
                    user ? (
                        <Redirect
                            to={{
                                pathname: '/search/?keyword=',
                                state: { from: props.location },
                            }}
                        />
                    ) : (
                        <Component {...props} />
                    )
                }
            />
        );
    }

    function RedirectBecomeLender({ component: Component, ...rest }) {
        return (
            <Route
                {...rest}
                render={props =>
                    user && user.isLender ? (
                        <Redirect
                            to={{
                                pathname: '/user/account',
                                state: { from: props.location },
                            }}
                        />
                    ) : (
                        <Component {...props} />
                    )
                }
            />
        );
    }
    return (
        <Elements stripe={stripe}>
            <GlobalErrorContext.Provider value={{ errorState, errorDispatch }}>
                <GlobalStateContext.Provider value={{ state, dispatch }}>
                    {loadingUser ? (
                        ''
                    ) : (
                        <Router>
                            <PageTracker/>
                            <ToastContainer
                                closeButton={false}
                                className='toaster-container'
                                position='top-right'
                                autoClose={5000}
                                hideProgressBar={true}
                                newestOnTop={false}
                                // closeOnClick
                                rtl={false}
                                pauseOnFocusLoss
                                draggable
                                pauseOnHover
                            />
                            <LBSSnackBar timeout={10000} />
                            <ScrollToTop>
                                {/* marketing pages here with different routers */}
                                {/* <Route exact path='/' component={Top} /> */}
                                <Route
                                    exact
                                    path='/lend_your_stuff'
                                    component={LendYourStuff}
                                />
                                <Route
                                    exact
                                    path='/privacy_policy'
                                    component={PrivacyPolicy}
                                />
                                <Route
                                    exact
                                    path='/rental_agreement'
                                    component={RentalAgreement}
                                />
                                <Route
                                    exact
                                    path='/cancellation_policy'
                                    component={CancellationPolicy}
                                />
                                <Route
                                    exact
                                    path='/damages_and_disputes'
                                    component={DamagesAndDisputes}
                                />
                                <Route
                                    exact
                                    path='/rent_stuff'
                                    component={RentStuff}
                                />
                                {/* <Route exact path='/how_it_works' component={HowItWorks} /> */}
                                {/* <Route exact path='/about_us' component={AboutUs} /> */}
                                {/* <Route exact path='/blog' component={Blog} /> */}
                                {/* <Route exact path='/protection' component={Protection} /> */}
                                {/* <Route exact path='/faqs' component={FAQs} /> */}
                                <Route
                                    exact
                                    path='/contact_us'
                                    component={ContactUs}
                                />
                                <Route
                                    exact
                                    path='/unsubscribe-lbs'
                                    component={UnSubscribe}
                                />

                                <Route exact path='/home' component={Home} />
                                <Route
                                    exact
                                    path='/item/:itemId'
                                    component={ItemPage}
                                />
                                <Route
                                    exact
                                    path='/search/:searchParams?'
                                    component={Search}
                                />
                                <Route
                                    exact
                                    path='/forgotpassword'
                                    component={ForgotPassword}
                                />
                                <AuthRoute
                                    exact
                                    path='/item/edit/:itemId'
                                    component={EditItemPage}
                                />
                                <AuthRoute
                                    exact
                                    path='/booking/extend/:bookingId'
                                    component={ExtendBookingApplication}
                                />
                                <AuthRoute
                                    path='/user/trades'
                                    component={TradesPage}
                                />
                                <AuthRoute
                                    path='/user/messages'
                                    component={Messages}
                                />
                                <AuthRoute
                                    path='/user/notifications'
                                    component={Notifications}
                                />
                                <AuthRoute
                                    path='/user/your_shed'
                                    component={YourshedPage}
                                />
                                <AuthRoute
                                    path='/user/favourites'
                                    component={FavouritesPage}
                                />
                                <AuthRoute
                                    path='/user/account'
                                    component={AccountPage}
                                />
                                <AuthRoute
                                    path='/user/update_password'
                                    component={UpdatePassword}
                                />
                                {/* if the user is already a lender they should be unable to access the upgrade to lender page */}
                                <RedirectBecomeLender
                                    path='/user/upgrade_to_lender'
                                    component={UpgradeLender}
                                />
                                <AuthRoute
                                    path='/item/:itemId/application'
                                    component={Application}
                                />

                                {/* post an item */}
                                <AuthRoute
                                    path='/postitem'
                                    component={PostItem}
                                />

                                {/* Lender Shed */}
                                <AuthRoute
                                    path='/lender-shed/:lenderId'
                                    component={LenderShed}
                                />

                                {/* Routes for login/register should redirect to user page if user is logged in */}
                                <AuthRedirectRoute
                                    path='/login'
                                    component={Login}
                                />
                                <AuthRedirectRoute
                                    path='/register'
                                    component={RegisterPage}
                                />

                                {/* <Route path="*" component={<Redirect to={{ pathname: '/' }}/>}/> */}
                            </ScrollToTop>
                        </Router>
                    )}
                </GlobalStateContext.Provider>
            </GlobalErrorContext.Provider>
        </Elements>
    );
}

export default App;
