import React, { useState } from 'react';
import classes from './AgreementModal.module.css';
import {CircularProgress, Dialog} from '@material-ui/core';
import parser from 'html-react-parser';
import Check from '../../../assets/Icons/Check';
import './AgreementModal.css';

const LenderAgreementModal = ({
    title,
    content,
    isLoading,
    open,
    onClose,
    onClick,
}) => {

    const [isReadAgreement, setIsReadAgreement] = useState(false);
    const [isReadPolicy, setIsReadPolicy] = useState(false);

    return (
        <Dialog open={open} onClose={onClose} className='max-w-[550px] m-auto'>
            <p className={classes.modal_title}>{title}</p>
            <p className={classes.modal_content}>{parser(content)}</p>
            <div className='lender-agreement-check'>
                <div
                    onClick={() => {
                        setIsReadAgreement(!isReadAgreement)
                    }}
                    className={`agree_check_Box ${isReadAgreement ? 'CheckBoxActive' : 'CheckBoxInactive'}`}
                >
                    {isReadAgreement && <Check />}
                </div>
                <div className="ml-3">I agree with lender's agreement</div>
            </div>
            <div className='lender-agreement-check'>
                <div
                    onClick={() => {
                        setIsReadPolicy(!isReadPolicy)
                    }}
                    className={`agree_check_Box ${isReadPolicy ? 'CheckBoxActive' : 'CheckBoxInactive'}`}
                >
                    {isReadPolicy && <Check />}
                </div>
                <div className="ml-3">I agree with lender's protection policy</div>
            </div>
            <div className={classes.modal_btn_container}>
                {isLoading ? (
                    <div className={classes.modal_loading}>
                        <CircularProgress style={{color: '#FFF'}} size={20} />
                    </div>
                ) : (
                    <button disabled={!(isReadAgreement && isReadPolicy)} className={classes.modal_btn_yes} onClick={onClick}>
                        Yes
                    </button>
                )}
                <button className={classes.modal_btn_no} onClick={onClose}>
                    No
                </button>
            </div>
        </Dialog>
    );
};

export default LenderAgreementModal;
