import {useState} from 'react';
import './UserShedNav.css';
import {ReactComponent as Trades} from '../../assets/Icons/Trades.svg';
import {ReactComponent as Messages} from '../../assets/Icons/Messages.svg';
import {ReactComponent as YourShed} from '../../assets/Icons/YourShed.svg';
import {ReactComponent as Favourites} from '../../assets/Icons/FavouritesIcon.svg';
import {ReactComponent as Account} from '../../assets/Icons/Account.svg';
import {ReactComponent as Logout} from '../../assets/Icons/LogoutIcon.svg';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import {Link, useHistory} from 'react-router-dom';
import useGlobalState from '../../util/useGlobalState';
import UpgradeToLenderConfirmationModal from '../modals/UpgradeToLenderConfirmationModel/ConfirmationModel';
import NotificationService from '../../services/notificationService';
import {CiBellOn} from 'react-icons/ci';

export default function UserShedNav(props) {
    const urlArr = window.location.href.split('/');
    const url = urlArr[urlArr.length - 1];
    const {state, dispatch} = useGlobalState();
    const {user, unReadMessageCount, unReadNotificationsCounts} = state;
    const history = useHistory();
    const notificationService = new NotificationService();
    const [activeMain, setActiveMain] = useState(url);
    const [isShowConfirmationModel, setIsShowConfirmationModel] =
        useState(false);

    const handleLogout = async () => {
        let fcmToken = localStorage.getItem('fcmToken');
        if (fcmToken) {
            await notificationService.removeFCMToken(fcmToken);
        }
        localStorage.removeItem('LBSToken');
        // localStorage.removeItem('fcmToken')
        dispatch({type: 'setUser', data: null});
    };

    const handleConfirm = () => {
        history.push(`/user/upgrade_to_lender`);
        props.setAccountContent('Become a Lender');
    };

    let notificationCount =
        unReadNotificationsCounts > 9 ? '9+' : unReadNotificationsCounts;

    return (
        <div className='UserShedNav' style={props.style}>
            <ul>
                <li className='mob-not-menu'>
                    <Link to='/user/trades'>
                        <div
                            className={`UserShedNav__LinkFlex ${
                                activeMain === 'trades'
                                    ? 'UserShedNav__LinkFlex--Active'
                                    : ''
                            }`}
                            onClick={() => setActiveMain('trades')}
                        >
                            <div className='menu-icon'>
                                <Trades height='28px' width='28px' />
                            </div>
                            Trades
                        </div>
                    </Link>
                </li>

                <li className='mob-not-menu'>
                    <Link to='/user/messages'>
                        <div
                            className={`UserShedNav__LinkFlex ${
                                activeMain === 'messages'
                                    ? 'UserShedNav__LinkFlex--Active'
                                    : ''
                            }`}
                            onClick={() => setActiveMain('messages')}
                        >
                            <div className='menu-icon'>
                                <Messages height='28px' width='28px' />
                            </div>
                            {unReadMessageCount > 0 && (
                                <span className='UserShedNav__NewMessage'></span>
                            )}
                            Messages
                            {unReadMessageCount > 0 && (
                                <span className='counter-pin'>
                                    {unReadMessageCount}
                                </span>
                            )}
                        </div>
                    </Link>
                </li>

                {user.isLender && (
                    <li className='mob-not-menu'>
                        <Link to='/user/your_shed'>
                            <div
                                className={`UserShedNav__LinkFlex ${
                                    activeMain === 'your_shed'
                                        ? 'UserShedNav__LinkFlex--Active'
                                        : ''
                                }`}
                                onClick={() => setActiveMain('your_shed')}
                            >
                                <div className='menu-icon'>
                                    <YourShed height='28px' width='28px' />
                                </div>
                                Your Shed
                            </div>
                        </Link>
                    </li>
                )}

                <li className='mob-not-menu'>
                    <Link to='/user/favourites'>
                        <div
                            className={`UserShedNav__LinkFlex ${
                                activeMain === 'favourites'
                                    ? 'UserShedNav__LinkFlex--Active'
                                    : ''
                            }`}
                            onClick={() => setActiveMain('favourites')}
                        >
                            <div className='menu-icon'>
                                <Favourites height='28px' width='28px' />
                            </div>
                            Favourites
                        </div>
                    </Link>
                </li>

                <li className='mob-not-menu'>
                    <Link to='/user/notifications'>
                        <div
                            className={`UserShedNav__LinkFlex ${
                                activeMain === 'notifications'
                                    ? 'UserShedNav__LinkFlex--Active'
                                    : ''
                            }`}
                            onClick={() => setActiveMain('notifications')}
                        >
                            <div className='menu-icon'>
                                <CiBellOn
                                    size={'50px'}
                                    className='UserShedNav__Notification_Icon'
                                />
                            </div>
                            Notifications
                            {notificationCount > 0 && (
                                <div className='counter-pin'>
                                    {notificationCount}
                                </div>
                            )}
                        </div>
                    </Link>
                </li>

                <li className='mob-not-menu'>
                    <Link to='/user/account'>
                        <div
                            className={`UserShedNav__LinkFlex ${
                                activeMain === 'account'
                                    ? 'UserShedNav__LinkFlex--Active'
                                    : ''
                            }`}
                            onClick={() => setActiveMain('account')}
                        >
                            <div className='menu-icon'>
                                <Account height='28px' width='28px' />
                            </div>
                            Account
                        </div>
                    </Link>
                </li>

                {activeMain === 'account' ? (
                    <div className='account-menu'>
                        <div className='account-submenu'>
                            <div
                                className={`UserShedNav__SecondaryLink ${
                                    props.accountContent === 'Account'
                                        ? 'UserShedNav__SecondaryLink--active'
                                        : ''
                                } UserShedNav__SecondaryLink__ExtraSpacing`}
                                onClick={() =>
                                    props.setAccountContent('Account')
                                }
                            >
                                Account & Settings
                                <ChevronRightIcon
                                    style={{fill: '#b43b4c'}}
                                    className='arrow'
                                />
                            </div>
                        </div>

                        <div className='HL' />
                        {!user.isLender ? (
                            // <Link to='/user/upgrade_to_lender'>
                            <div className='account-submenu'>
                                <div>
                                    <div
                                        className={`UserShedNav__SecondaryLink ${
                                            props.accountContent ===
                                            'Become a Lender'
                                                ? 'UserShedNav__SecondaryLink--active'
                                                : ''
                                        }`}
                                        onClick={() =>
                                            setIsShowConfirmationModel(true)
                                        }
                                    >
                                        Become Lender
                                        <ChevronRightIcon
                                            style={{fill: '#b43b4c'}}
                                            className='arrow'
                                        />
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className='account-submenu'>
                                <div
                                    className={`UserShedNav__SecondaryLink ${
                                        props.accountContent === 'Availability'
                                            ? 'UserShedNav__SecondaryLink--active'
                                            : ''
                                    }`}
                                    onClick={() =>
                                        props.setAccountContent('Availability')
                                    }
                                >
                                    Availability
                                    <ChevronRightIcon
                                        className='arrow'
                                        style={{fill: '#b43b4c'}}
                                    />
                                </div>
                            </div>
                        )}

                        <div className='HL' />
                        <div className='account-submenu'>
                            <div
                                className={`UserShedNav__SecondaryLink ${
                                    props.accountContent ===
                                    'Terms & Conditions'
                                        ? 'UserShedNav__SecondaryLink--active'
                                        : ''
                                }`}
                                onClick={() =>
                                    props.setAccountContent(
                                        'Terms & Conditions',
                                    )
                                }
                            >
                                Terms & Conditions
                                <ChevronRightIcon
                                    style={{fill: '#b43b4c'}}
                                    className='arrow'
                                />
                            </div>
                        </div>

                        <div className='HL' />
                        <div className='account-submenu'>
                            <div
                                className={`UserShedNav__SecondaryLink ${
                                    props.accountContent === 'Support'
                                        ? 'UserShedNav__SecondaryLink--active'
                                        : ''
                                }`}
                                onClick={() =>
                                    props.setAccountContent('Support')
                                }
                            >
                                Support
                                <ChevronRightIcon
                                    style={{fill: '#b43b4c'}}
                                    className='arrow'
                                />
                            </div>
                        </div>

                        <div className='HL' />
                        <div className='account-submenu'>
                            <div
                                className={`UserShedNav__SecondaryLink ${
                                    props.accountContent === 'Disputes'
                                        ? 'UserShedNav__SecondaryLink--active'
                                        : ''
                                }`}
                                onClick={() =>
                                    props.setAccountContent('Disputes')
                                }
                            >
                                Disputes
                                <ChevronRightIcon
                                    style={{fill: '#b43b4c'}}
                                    className='arrow'
                                />
                            </div>
                        </div>

                        <div className='HL' />
                    </div>
                ) : (
                    ''
                )}
                <UpgradeToLenderConfirmationModal
                    isModalOpen={isShowConfirmationModel}
                    setIsModalOpen={setIsShowConfirmationModel}
                    handleConfirm={handleConfirm}
                />
            </ul>
            <div className='logout-section mob-not-menu'>
                <button className='LogoutButton' onClick={() => handleLogout()}>
                    <div className='menu-icon'>
                        <Logout height='30px' width='30px' />
                    </div>
                    Logout
                </button>
            </div>
        </div>
    );
}
