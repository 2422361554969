import './BookingItemCard.css';
import getImage from '../../util/getImage';
import EmptyImage from '../../assets/Logos/LBS_Logo_Flat_FullColour.png';
import {Booking} from '../../types/Booking';

type Props = {
    booking: Booking;
};

export default function BookingItemCard({booking}: Props) {
    const mainItemImage =
        booking.item?.images?.length !== 0
            ? getImage(booking.item?.images?.[0]?.imageKey)
            : EmptyImage;

    const formatString = (getString: string) => {
        return getString
            .split('_')
            .map(
                word =>
                    word.charAt(0).toUpperCase() + word.slice(1).toLowerCase(),
            )
            .join(' ');
    };
    return (
        <div className='BookingItemCardContainer'>
            <img
                placeholder='item image'
                src={mainItemImage}
                className='BookingItemImage'
                alt='item_image'
            />
            <div className='BookingItemDetailsContainer'>
                <span className='BookingItemCardHeader'>
                    {booking.item.title}
                </span>
                <div>
                    <span className='BookingItemPrice'>
                        {Number(booking.item.price)
                            ? `$${booking.item.price}`
                            : 'Free'}{' '}
                    </span>
                </div>
                <div className='BookingItemRatingContainer'>
                    <span className='BookingItemRating'>{formatString(booking.status)}</span>
                </div>
            </div>
        </div>
    );
}
