import  {useState} from 'react';
import {CircularProgress, Dialog, DialogContent} from '@material-ui/core';
import {useHistory} from 'react-router-dom';
import getChannelId from '../../../util/checkIsChannelExist';
import socket from '../../../socket';

type Props = {
    onClick: () => void;
    userId: string;
    isOpen: boolean;
    sender: any;
};

const ColdChatModal = ({onClick, userId, isOpen, sender}: Props) => {
    const [chatText, setChatText] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const history = useHistory();

    const startColdChat = async () => {
        if (chatText === '') onClick();

        let channelId = await getChannelId(sender?.id!, userId);
    
        try {
            setIsLoading(true);

            const message = {
                text: chatText,
                userId: sender?.id,
                receiverId : userId,
                metadata: {},
                user: {
                    username: sender?.firstName,
                },
                channelId
            }
            socket.emit('chat', message);

            history.push('/user/messages');
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Dialog open={isOpen} onClose={onClick} className='cus-modal'>
            <DialogContent className='BorrowerMain'>
                <span className='ApplicationModalTitle'>Start Chat</span>
                <br></br>
                {/* <span className="ApplicationModalText">Add some text to prompt users to send a message</span> */}
                <textarea
                    rows={10}
                    maxLength={254}
                    className='LoginInput PostItem__TextArea'
                    onChange={e => setChatText(e.target.value)}
                />
                {isLoading ? (
                    <div
                        className='ApplicationModalButton'
                        style={{margin: '1rem 0'}}
                    >
                        <CircularProgress size={30} style={{color: '#FFF'}} />
                    </div>
                ) : (
                    <div
                        className='ApplicationModalButton'
                        onClick={startColdChat}
                        style={{margin: '1rem 0'}}
                    >
                        <span className='ApplicationModalButtonText'>Send</span>
                    </div>
                )}
            </DialogContent>
        </Dialog>
    );
};

export default ColdChatModal;
