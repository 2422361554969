import React, {useEffect, useState} from 'react';
import ValidationTextInput from '../FormComponents/ValidationTextInput';
import CategorySelect from '../categorySelect/categorySelect';
import ClearIcon from '@material-ui/icons/Clear';
import PlusIcon from '@material-ui/icons/Add';
import ValidationPopup from '../ValidationPopup/ValidationPopup';

export default function EditBasicDetails({state, dispatch, Errors, setErrors}) {
    const {
        editItemTitle,
        editItemCategory,
        editItemDescription,
        editItemSpecification,
    } = state;

    const [specificationEntries, setSpecificationEntries] = useState(
        Object.entries(editItemSpecification).map(([key, value]) => ({
            id: Math.random().toString(36).substring(2), // Unique ID
            key,
            value,
        })),
    );

    const handleInputChange = (id, field, value) => {
        if (Errors.specification) {
            setErrors(prevErrors => {
                const {specification, ...rest} = prevErrors; // Destructure to exclude `specification`
                return rest; // Return the updated state without `specification`
            });
        }
        const updatedEntries = specificationEntries.map(entry => {
            if (entry.id === id) {
                if (field === 'key') {
                    return {
                        ...entry,
                        key:
                            value.charAt(0).toUpperCase() +
                            value.slice(1).toLowerCase(),
                    };
                } else if (field === 'value') {
                    return {
                        ...entry,
                        value,
                    };
                }
            }
            return entry;
        });

        setSpecificationEntries(updatedEntries);

        // Optionally, convert it back to an object to dispatch
        const updatedObject = updatedEntries.reduce(
            (acc, {key, value}) => ({...acc, [key]: value}),
            {},
        );
        dispatch({
            type: 'setEditItemSpecification',
            data: updatedObject,
        });
    };

    const addKeyValuePair = () => {
        let newfield = {
            key: `key${Object.keys(editItemSpecification).length + 1}`,
            value: '',
            id: Math.random().toString(36).substring(2),
        };
        let copy = [...specificationEntries, newfield];
        setSpecificationEntries(copy);
        const updatedObject = copy.reduce(
            (acc, {key, value}) => ({...acc, [key]: value}),
            {},
        );
        dispatch({
            type: 'setEditItemSpecification',
            data: updatedObject,
        });
    };

    const removeKeyValuePair = id => {
        if (Errors.specification) {
            setErrors(prevErrors => {
                const {specification, ...rest} = prevErrors; // Destructure to exclude `specification`
                return rest; // Return the updated state without `specification`
            });
        }

        const filteredEntries = specificationEntries.filter(
            entry => entry.id !== id,
        );
        setSpecificationEntries(filteredEntries);

        const updatedObject = filteredEntries.reduce(
            (acc, {key, value}) => ({...acc, [key]: value}),
            {},
        );
        dispatch({
            type: 'setEditItemSpecification',
            data: updatedObject,
        });
    };

    return (
        <>
            <div
                className='LoginMain'
                style={{width: '100%', marginTop: '0.5%'}}
            >
                <div className='LoginHeader'>Basic Item Details</div>
                <ValidationTextInput
                    label='Title'
                    placeholder={editItemTitle}
                    value={editItemTitle}
                    onChange={e =>
                        dispatch({
                            type: 'setEditItemTitle',
                            data: e.target.value,
                        })
                    }
                />
                <div className='LoginHeader'>Category</div>
                <div className='LoginHeader'>
                    <CategorySelect
                        width='100%'
                        // label='Category'
                        thinBorder
                        setCategory={newCategory =>
                            dispatch({
                                type: 'setEditItemCategory',
                                data: newCategory,
                            })
                        }
                        value={editItemCategory}
                    />
                </div>
                <div className='LoginHeader'>Description</div>
                <textarea
                    rows='10'
                    maxLength='254'
                    defaultValue={editItemDescription}
                    className='LoginInput PostItem__TextArea'
                    onChange={e =>
                        dispatch({
                            type: 'setEditItemDescription',
                            data: e.target.value,
                        })
                    }
                />
            </div>
            <div
                className='LoginMain'
                style={{width: '100%', marginTop: '0.5%'}}
            >
                <div className='LoginHeader'>Extra Item Details</div>
                <div className='LoginText'>
                    Use the form below to dynamically add or edit extra details
                    for an item. Click 'Add Field' to add more details, or click
                    the cross icon to delete a field.
                </div>
                <div>
                    {specificationEntries.map(({id, key, value}) => (
                        <div
                            key={id}
                            className='item-specification-Flex LoginHeader'
                        >
                            <ValidationTextInput
                                maxLength={30}
                                inputType='text'
                                placeholder='Enter Title'
                                value={key}
                                onChange={e =>
                                    handleInputChange(id, 'key', e.target.value)
                                }
                            />
                            <ValidationTextInput
                                inputType='text'
                                placeholder='Enter Value'
                                maxLength={30}
                                value={value}
                                onChange={e =>
                                    handleInputChange(
                                        id,
                                        'value',
                                        e.target.value,
                                    )
                                }
                                style={{marginRight: '10px'}}
                            />
                            <ClearIcon
                                style={{color: '#ac172c'}}
                                onClick={() => removeKeyValuePair(id)}
                            />
                        </div>
                    ))}
                    <div className='add-key-value-btn'>
                        <button
                            onClick={addKeyValuePair}
                            className='ButtonAddKeyValue'
                        >
                            Add Field
                            <PlusIcon />
                        </button>
                    </div>
                    {Errors.specification && (
                        <ValidationPopup
                            errorText={Errors.specification}
                            label={'specification'}
                        />
                    )}
                </div>
            </div>
        </>
    );
}
