import { createChannel, getChannelsByUser } from '../services/channel';

const checkIsChannelExist = async (userId: string, otherId: string) => {
  const { channels } = await getChannelsByUser(userId);
  var channelId: string = '';

  for (let i = 0; i < channels.length; i++) {
    const result = await channels[i].participants.includes(otherId);
    if (result && channels[i].participants.length === 2 && !channels[i].name) return (channelId = channels[i].id);
  } 

  return channelId;
};

const getChannelId= async (userId: string, otherId:string)=>{
  let channelId = await checkIsChannelExist(userId!, otherId);

  if(!channelId){
    const { channel } = await createChannel({
        participants: [
          userId,
          otherId
        ]
    });
    channelId = channel.id
 }
 return channelId;
}
export default getChannelId;
