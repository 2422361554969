import getImage from '../../../util/getImage';
import useGlobalState from '../../../util/useGlobalState';
import './SentMessage.css';
import MissingProfile from '../../../assets/Icons/MissingProfileIcon.png';
import {Avatar} from '@material-ui/core';
import BrownTriangle from '../../../assets/Icons/BrownTriangle';
import moment from 'moment';
export default function SentMessage({message, handlePreview}) {
    const {state} = useGlobalState();
    const {user} = state;
    const renderImages = images => {
        return images?.map((image, index) => (
            <>
                <div
                    className='attchments-upload_item_image_box'
                    onClick={() => handlePreview(images)}
                    key={index}
                >
                    <img
                        src={getImage(image)}
                        alt=''
                        className='attchments-upload_item_image'
                    />
                </div>
            </>
        ));
    };
    const showTimestamp =(date)=>{
        const isToday = moment(date).isSame(moment(), 'day');

       return  isToday ? moment(date).format('LT'): moment(date).format('lll');
    }
    return (
        <div className='SentMessageContainer'>
            <div>
                {message.images?.length > 0 && (
                    <>
                        <div className='flex gap-2 mb-[3px]'>
                            {renderImages(message.images)}
                        </div>
                        <p className='send-message-timestamp'>
                        {showTimestamp(message.createdAt)}
                        </p>
                    </>
                )}
                {message?.text && (
                    <>
                        <div className='SentMessageTextContainer'>
                            <p>{message.text}</p>
                            <BrownTriangle />
                        </div>
                        <p className='send-message-timestamp'>
                        {showTimestamp(message.createdAt)}
                        </p>
                    </>
                )}
            </div>
            <div className='SentMessageImageContainer'>
                <Avatar
                    src={user ? getImage(user.avatar) : MissingProfile}
                    className='SentMessageImage'
                />
            </div>
        </div>
    );
}
