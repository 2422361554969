import './userReviewCard.css';
import getImage from '../../util/getImage';
import {Avatar} from '@material-ui/core';
import MissingProfile from '../../assets/Icons/MissingProfileIcon.png';
import RatingFiller from '../ratingFiller/ratingFiller';

type Props = {
    review: any;
    isOnlyOne: boolean;
};

export default function UserReviewCard({review, isOnlyOne}: Props) {
    return (
        <div className={isOnlyOne ? 'user-OnlyOneCard' : 'user-ReviewCard'}>
            <div className='RatingLenderFlex'>
                <Avatar
                    src={
                        review?.lender?.avatar
                            ? getImage(review.lender?.avatar)
                            : MissingProfile
                    }
                    className='user-ProfileIconSmall'
                />
                <div className='ReviewSmallText'>
                    <div className='RatingHeader'>
                        {review.lender.firstName}&nbsp;
                        {review.lender.lastName}
                    </div>
                    <div className='user-ReviewRatingTitle'>
                        {review.rating}/5
                        <RatingFiller rating={review.rating} />
                    </div>
                </div>
            </div>
            <div className='user-ReviewText'>{review.comment}</div>
        </div>
    );
}
