import {createContext, useEffect, useReducer, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {REGISTER_PAGES, SNACKBAR_BUTTON_TYPES} from '../../assets/Data/LBSEnum';
import Banner from '../../components/bannerText/bannerText.js';
import BasicDetails from '../../components/FormComponents/BasicDetails';
import TermsConditions from '../../components/FormComponents/TermsConditions';
import Verification from '../../components/FormComponents/Verification';
import PageWrapper from '../../components/pageWrapper/pageWrapper.js';
import NotificationService from '../../services/notificationService';
import UserService from '../../services/user';
import Instance from '../../util/axios';
import {blockedAvailabilityToNumber} from '../../util/blockedAvailabilityToNumber';
import {getPrevRegisterPage} from '../../util/getPrevPage';
import useErrorState from '../../util/reducers/errorContext';
import registerReducer from '../../util/reducers/registerReducer';
import useGlobalState from '../../util/useGlobalState';
import {ReactComponent as Logo} from './../../assets/Logos/LogoRed.svg';
import './register.css';
import CheckBox from '../../components/checkBox/CheckBox';
import {MESSAGES} from '../../util/Message';
import Button from '../../components/Button/Button';

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID,
};

// const firebaseApp = initializeApp(firebaseConfig)
// const analytics = getAnalytics(firebaseApp)
const FormContext = createContext();
// const messaging = getMessaging(firebaseApp);
export default function Register() {
    const [isRegisterLoading, setIsRegisterLoading] = useState(false);
    const [isSubscribeLoading, setIsSubscribeLoading] = useState(false);

    const [isSubscribe, setIsSubscribe] = useState(false);

    const globalDispatch = useGlobalState().dispatch;
    const {errorDispatch} = useErrorState();
    const notificationService = new NotificationService();

    const userService = new UserService();
    const [state, dispatch] = useReducer(registerReducer, {
        isLenderUpgrade: false,
        currentPage: REGISTER_PAGES.BASIC,
        dateOfBirth: '',
        blockedAvailabilities: [],
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        password: '',
        confirmPassword: '',
        termsChecked: false,
        tempUser:{}
    });
    const {
        firstName,
        lastName,
        email,
        phoneNumber,
        password,
        address,
        currentPage,
        imageLink,
        isLenderUpgrade,
        blockedAvailabilities,
        tempUser
    } = state;

    const history = useHistory();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [currentPage]);

    function removeThirdIndexZero(input) {
        // Convert the input to a string and use a regex to remove the zero at the third index, if it exists
        return '+' + input.toString().replace(/^(.{2})0/, '$1');
    }

    const getUserDetails = () => {
        if (isLenderUpgrade) {
            const lenderDetails = {
                lenderDetails: {
                    firstName,
                    lastName,
                    email,
                    avatar: imageLink ? imageLink : '',
                    mobile: removeThirdIndexZero(phoneNumber),
                    password: password,
                    address,
                    isLender: true,
                },
            };
            return lenderDetails;
        } else {
            const borrowerDetails = {
                borrowerDetails: {
                    firstName,
                    lastName,
                    email,
                    avatar: imageLink ? imageLink : '',
                    mobile: removeThirdIndexZero(phoneNumber),
                    password: password,
                    isLender: false,
                },
            };
            return borrowerDetails;
        }
    };

    const updateUserBlockedAvailability = async userId => {
        try {
            // setIsLoading(true)
            const userBlockedAvailabilityNumberFormat =
                blockedAvailabilities?.map(availability => {
                    return {
                        weekDay: blockedAvailabilityToNumber(
                            availability.weekDay,
                        ),
                        startTime: availability.startTime,
                        endTime: availability.endTime,
                    };
                });
            const blockedAvailability =
                await userService.updateUserBlockedAvailability(
                    userId,
                    userBlockedAvailabilityNumberFormat,
                );
            dispatch({
                type: 'setUserBlockedAvailability',
                data: blockedAvailability,
            });
            // setAccountContent('Account')
        } catch (error) {
            errorDispatch({
                type: 'openSnackBar',
                data: {
                    message:
                        'Failed to update availability. Please check your details and try again later.',
                    btnText: SNACKBAR_BUTTON_TYPES.CLOSE,
                    btnFunc: () => errorDispatch({type: 'closeSnackBar'}),
                },
            });
        } finally {
            // setIsLoading(false)
        }
    };

    const registerUser = async () => {
        setIsRegisterLoading(true);
        const signupDetails = getUserDetails();
        try {
            const {data, status} = await Instance.post(
                isLenderUpgrade
                    ? '/auth/lender/signUpV2'
                    : '/auth/borrower/signUpV2',
                signupDetails,
            );
            if (status === 201) {
                dispatch({type: 'setTempUser', data: data.user});
                localStorage.setItem('LBSToken', data.token.accessToken);
                updateUserBlockedAvailability(data.user.id);
                setIsRegisterLoading(false);
                dispatch({
                    type: 'setCurrentPage',
                    data: REGISTER_PAGES.COMPLETE,
                });
                let fcmToken = localStorage.getItem('fcmToken');
                if (fcmToken) {
                    await notificationService.saveFCMToken(fcmToken);
                }
                // logEvent(analytics, 'user_register_web')
            }
        } catch (e) {
            const messageType = e?.response?.data?.message?.split(':')[0];
            if (e?.response?.data?.statusCode === 402) {
                errorDispatch({
                    type: 'openSnackBar',
                    data: {
                        message: `${e?.response?.data?.message} Please check your bank details and try again.`,
                        btnText: SNACKBAR_BUTTON_TYPES.RETRY,
                        btnFunc: () => {
                            dispatch({
                                type: 'setCurrentPage',
                                data: REGISTER_PAGES.BANK,
                            });
                            errorDispatch({type: 'closeSnackBar'});
                        },
                    },
                });
            } else if (messageType === 'Invalid request to stripe') {
                errorDispatch({
                    type: 'openSnackBar',
                    data: {
                        message:
                            'Invalid bank infomation. Please check your bank details and try again.',
                        btnText: SNACKBAR_BUTTON_TYPES.RETRY,
                        btnFunc: () => {
                            dispatch({
                                type: 'setCurrentPage',
                                data: REGISTER_PAGES.BANK,
                            });
                            errorDispatch({type: 'closeSnackBar'});
                        },
                    },
                });
            } else {
                errorDispatch({
                    type: 'openSnackBar',
                    data: {
                        message:
                            'Failed to register. Please check your details and try again.',
                        btnText: SNACKBAR_BUTTON_TYPES.CLOSE,
                        btnFunc: () => errorDispatch({type: 'closeSnackBar'}),
                    },
                });
            }
        } finally {
            setIsRegisterLoading(false);
        }
    };

    const notify = message => {
        errorDispatch({
            type: 'openSnackBar',
            data: {
                message,
                btnText: SNACKBAR_BUTTON_TYPES.CLOSE,
                btnFunc: () => errorDispatch({type: 'closeSnackBar'}),
            },
        });
    };

    const sendSubscribeEmail = async () => {
        if (isSubscribe) {
            setIsSubscribeLoading(true);
            try {
                const result = await Instance.post('/news-latter/subscribe', {
                    email,
                    isSubscribe: isSubscribe,
                });
                if (result.status !== 201) {
                    throw Error;
                }

                notify(MESSAGES.SUCCESS_NEWS_LETTER);
            } catch (error) {
                notify(MESSAGES.ERROR_NEWS_LETTER);
            } finally {
                setIsSubscribeLoading(false);
            }
        }
        globalDispatch({type: 'setUser', data: tempUser});
        // history.push({pathname: '/search/?keyword='});
    };
    const getComplete = () => {
        return (
            <div className='RegistrationWrapper'>
                <div className='LoginMain'>
                    <Logo
                        height='50px'
                        width='50px'
                        style={{marginBottom: '1em'}}
                    />
                    <div className='LoginHeader'>Account Created</div>
                    <div className='LoginText'>
                        You have successfully created your Little Big Shed
                        account and are now ready to start borrowing!
                    </div>
                    <div className='w-[100%] flex justify-between items-center pb-4'>
                        <CheckBox
                            checked={isSubscribe}
                            onClick={() => setIsSubscribe(!isSubscribe)}
                        />
                        <div className='w-[90%]'>
                            Subscribe to the Little Big Shed newsletter to keep
                            up to date with our sharing news and new item
                            listings!
                        </div>
                    </div>

                    <Button
                        text='Continue'
                        isLoading={isSubscribeLoading}
                        onClick={() => sendSubscribeEmail()}
                    />
                </div>
            </div>
        );
    };

    const renderCurrentPage = () => {
        switch (currentPage) {
            case REGISTER_PAGES.BASIC:
                return <BasicDetails context={FormContext} />;
            case REGISTER_PAGES.VERIFICATION:
                return (
                    <Verification
                        context={FormContext}
                        isLender={isLenderUpgrade}
                    />
                );
            case REGISTER_PAGES.TNC:
                return (
                    <TermsConditions
                        context={FormContext}
                        registerUser={registerUser}
                        isRegisterLoading={isRegisterLoading}
                    />
                );
            case REGISTER_PAGES.COMPLETE:
                return getComplete();
            default:
                return '';
        }
    };

    return (
        <FormContext.Provider value={{state, dispatch}}>
            <PageWrapper>
                <Banner
                    textBold='Account Creation'
                    textNormal={currentPage}
                    prevPage={() =>
                        getPrevRegisterPage(
                            isLenderUpgrade,
                            currentPage,
                            dispatch,
                            history,
                        )
                    }
                />
                {renderCurrentPage()}
            </PageWrapper>
        </FormContext.Provider>
    );
}
