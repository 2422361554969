import React from 'react';
import classes from './AgreementModal.module.css';
import {CircularProgress, Dialog} from '@material-ui/core';
import parser from 'html-react-parser';

const BorrowerAgreementModal = ({
    title,
    content,
    isLoading,
    open,
    onClose,
    onClick,
}) => {
    return (
        <Dialog open={open} onClose={onClose} className='max-w-[450px] m-auto'>
            <p className={classes.modal_title}>{title}</p>
            <p className={classes.modal_content}>{parser(content)}</p>
            <div className={classes.modal_btn_container}>
                {isLoading ? (
                    <div className={classes.modal_loading}>
                        <CircularProgress style={{color: '#FFF'}} size={20} />
                    </div>
                ) : (
                    <button className={classes.modal_btn_yes} onClick={onClick}>
                        Yes
                    </button>
                )}
                <button className={classes.modal_btn_no} onClick={onClose}>
                    No
                </button>
            </div>
        </Dialog>
    );
};

export default BorrowerAgreementModal;