const passwordConstraints = {
    presence: {
        allowEmpty: false,
        message: '^Required',
    },
    length: {
        minimum: 8,
		maximum: 30,
		tooShort: '^Must be at least 8 characters',
		tooLong: '^Must be no more than 30 characters',
    },
    format: {
        pattern:
            /^(?=.*[A-Za-z])(?=.*\d.*\d)(?=.*[!@#$%^&*()_\-+={[}\]:;"'<,>.?/|\\])[A-Za-z\d!@#$%^&*()_\-+={[}\]:;"'<,>.?/|\\]{8,30}$/,
        flags: 'i',
        message:
            '^Must have at least 2 numbers,1 character and 1 special character and a maximum length of 30 characters.',
    },
};
const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

const validationConstraints = {
    firstName: {
        presence: {
            allowEmpty: false,
            message: '^Required',
        },
    },
    lastName: {
        presence: {
            allowEmpty: false,
            message: '^Required',
        },
    },
    email: {
        presence: {
            allowEmpty: false,
            message: '^Required',
        },
        email: {
            message: '^Must be a valid email address',
        },
        format: {
            pattern: emailPattern,
            message: '^Must be a valid email address',
        },
    },
    phoneNumber: {
        presence: {
            allowEmpty: false,
            message: '^Required',
        },
        format: {
            pattern:
                /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im,
            message: '^Must be a valid phone number',
        },
    },
    password: passwordConstraints,
    confirmPassword: {
        equality: 'password',
    },
    currentPassword: passwordConstraints,
    postItemDiscount: {
        numericality: {
            onlyInteger: true,
            greaterThanOrEqualTo: 0,
            lessThanOrEqualTo: 99,
            message: 'Must be less than or equal to 99%',
        },
    },
    streetNumber: {
        presence: {
            allowEmpty: false,
            message: '^Street Number is required',
        },
    },
    streetName: {
        presence: {
            allowEmpty: false,
            message: '^Street Name is required',
        },
    },
    suburb: {
        presence: {
            allowEmpty: false,
            message: '^Suburb is required',
        },
    },
    state: {
        presence: {
            allowEmpty: false,
            message: '^State is required',
        },
    },
    postCode: {
        presence: {
            allowEmpty: false,
            message: '^Post Code is required',
        },
    },
    country: {
        presence: {
            allowEmpty: false,
            message: '^Country is required',
        },
        inclusion: {
            within: ['NZ', 'AU'],
            message: '^Country must be either NZ or AU',
        },
    },
    city: {
        presence: {
            allowEmpty: false,
            message: '^City is required',
        },
    },
    message: {
        presence: {
            allowEmpty: false,
            message: '^Required',
        },
    },
    accountHolderName: {
        presence: {
            allowEmpty: false,
            message: '^Required',
        },
        format: {
            pattern: /^[A-Za-z\s]+$/,
            message: '^Only alphabets are allowed',
        },
    },
    accountNumber: {
        presence: {
            allowEmpty: false,
            message: '^Required',
        },
        format: {
            pattern: /^\d+$/, // Only allow numbers
            message: '^Must contain only numbers',
        },
    },
};

const createValidationObject = fields => {
    const validationObj = {};
    fields.forEach(field => {
        validationObj[field] = validationConstraints[field];
    });
    return validationObj;
};

export const loginConstraints = createValidationObject(['email', 'password']);
export const contactUsConstraints = createValidationObject(['email']);
export const registrationConstraints = createValidationObject([
    'firstName',
    'lastName',
    'email',
    'phoneNumber',
    'password',
    'confirmPassword',
]);
export const newPasswordConstraints = createValidationObject([
    'password',
    'confirmPassword',
]);
export const contactUsDetailsConstraints = createValidationObject([
    'firstName',
    'lastName',
    'email',
    'phoneNumber',
    'message'
]);
export const updateUserDetailsConstraints = createValidationObject([
    'firstName',
    'lastName',
    'email',
    'phoneNumber',
]);
export const discountConstraints = createValidationObject(['postItemDiscount']);

export const userAddressConstraints = createValidationObject([
    'streetNumber',
    'streetName',
    'suburb',
    'state',
    'postCode',
    'country',
    'city',
]);

export const bankDetailsConstraints = createValidationObject([
    'accountHolderName',
    'accountNumber',
]);

export const isValidUrl = str => {
    const pattern = new RegExp(
        '^([a-zA-Z]+:\\/\\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR IP (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$', // fragment locator
        'i',
    );
    return pattern.test(str);
};
export const forgetPasswordEmailConstraints = createValidationObject(['email']);
export const emailConstraints = createValidationObject(['email']);
