import React, {useState, useEffect} from 'react';
import './imagesPreviewModal.css';
import CloseIcon from '@material-ui/icons/Close';
import ArchiveIcon from '@material-ui/icons/Archive';
import GetAppIcon from '@material-ui/icons/GetApp';
import {Fade} from '@material-ui/core';
import getImage from '../../util/getImage';
import {CircularProgress} from '@material-ui/core';

export default function ImagesPreviewModal({setModal, images, modal}) {
    const itemImages = images;

    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [isDownlaoding, setIsDownlaoding] = useState(false);
    const getBigImages = currentIndex => {
        let content = [];
        // Push off screen left if we're not on first image
        if (currentIndex > 0) {
            content.push(
                <img
                    src={getImage(itemImages[currentIndex - 1])}
                    alt='left'
                    className='ImageModalImage OffScreenBackward'
                    key={currentIndex - 1}
                    onClick={e => {
                        setCurrentImageIndex(currentImageIndex - 1);
                        e.stopPropagation();
                    }}
                />,
            );
        }

        //Push off screen right if we're not on last image
        if (currentIndex < itemImages.length - 1) {
            content.push(
                <img
                    src={getImage(itemImages[currentIndex + 1])}
                    alt='right'
                    className='ImageModalImage OffScreenForward'
                    key={currentIndex + 1}
                    onClick={e => {
                        setCurrentImageIndex(currentImageIndex + 1);
                        e.stopPropagation();
                    }}
                />,
            );
        }

        //Push main focus image
        content.push(
            <div style={{position: 'relative'}}>
                <img
                    src={getImage(itemImages[currentImageIndex])}
                    alt='center'
                    className='ImageModalImage'
                    key={currentIndex}
                    onClick={e => e.stopPropagation()}
                />
                <div
                    onClick={e => {
                        e.stopPropagation();
                        downloadImage(getImage(itemImages[currentImageIndex]));
                    }}
                    className='download-button'
                >
                    {isDownlaoding ? (
                       <CircularProgress color='secondary' size={20} />
                    ) : (
                        <GetAppIcon fontSize='large' />
                    )}
                </div>
            </div>,
        );
        return content;
    };

    const getSmallImages = () => {
        let content = [];
        itemImages.map((_, i) => {
            content.push(
                <div className='ImageSmallContainer' key={i}>
                    <img
                        src={getImage(itemImages[i])}
                        alt=''
                        className={`ImageModalSmall ${i === currentImageIndex ? 'SmallImageActive' : ''}`}
                        onClick={e => e.stopPropagation()}
                    />
                    <div
                        className={`SmallImageInactive ${i === currentImageIndex ? 'HideOverlay' : ''}`}
                        onClick={e => {
                            setCurrentImageIndex(i);
                            e.stopPropagation();
                        }}
                    />
                </div>,
            );
            return 1;
        });
        return content;
    };

    const closeModal = () => {
        setModal(false);
    };

    useEffect(() => {
        document.body.style.overflow = 'hidden';
        return function releaseScroll() {
            document.body.style.overflow = 'unset';
        };
    }, []);

    // const downloadImage = image => {
    //     const fileName = image.split('/').pop().split('?')[0]; // Extract file name
    //     const link = document.createElement('a');
    //     link.href = "https://prod.littlebigsocial.com/file-uploads?key=items/f0f06af0-9ff0-11ef-a597-8d76ab5d780b.jpeg";
    //     link.download = "image-preview.jpg";
    //     document.body.appendChild(link);
    //     link.click();
    //     document.body.removeChild(link);
    // };
    const downloadImage = async image => {
        setIsDownlaoding(true);
        try {
            // Fetch the image as a blob
            const response = await fetch(image);
            if (!response.ok) throw new Error('Failed to fetch the file.');

            const blob = await response.blob();

            // Create a temporary URL for the blob
            const blobURL = URL.createObjectURL(blob);
            // Create an anchor tag and trigger download

            const link = document.createElement('a');
            link.href = blobURL;
            link.download = `little-big-shed-img.jpg`;
            document.body.appendChild(link);
            link.click();
            setIsDownlaoding(false);
            // Clean up
            document.body.removeChild(link);
            URL.revokeObjectURL(blobURL); // Revoke the temporary URL
        } catch (error) {
            console.error('Error downloading the image:', error);
        }
    };
    return (
        <div className='ItemImageModalContainer' onClick={() => closeModal()}>
            <Fade in={modal} timeout={500}>
                <div className='CarouselModalFlexContainer'>
                    <button
                        className='ItemImageModalCloseBtn'
                        onClick={() => closeModal()}
                    >
                        <CloseIcon />
                    </button>
                    <div className='ImageModal'>
                        {getBigImages(currentImageIndex)}
                        <div className='ImageNumberDisplay'>
                            {currentImageIndex + 1}/{itemImages.length}
                        </div>
                    </div>
                    <div className='ImageModalCarousel'>{getSmallImages()}</div>
                </div>
            </Fade>
        </div>
    );
}
