import {useEffect, useState, useRef} from 'react';
import './ActiveChat.css';
import useGlobalState from '../../../util/useGlobalState';
import {Avatar, CircularProgress, IconButton} from '@material-ui/core';
import ReceivedMessage from '../receivedMessage/ReceivedMessage';
import SentMessage from '../sentMessage/SentMessage';
import EnquiryMessage from '../equiryMessage/EnquiryMessage';
import MissingProfile from '../../../assets/Icons/MissingProfileIcon.png';
import Instance from '../../../util/axios';
import {ReactComponent as StarFilled} from '../../../assets/Icons/StarFilled.svg';
import getImage from '../../../util/getImage';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import Arrow from '../../../assets/Icons/Arrow';
import {isMobile} from 'react-device-detect';
import {getMessagesByChannel} from '../../../services/message';
import socket from '../../../socket';
import {FileService} from '../../../services/FileService';
import {v4 as uuidv4} from 'uuid';
import RemoveIcon from '@material-ui/icons/Remove';
import ImagesPreviewModal from '../../imagesPreviewModal/imagesPreviewModal';

export default function ActiveChat({
    activeChannel,
    setActiveChannel,
    activeMessages,
    setActiveMessages,
    getConversations,
    setChannels,
}) {
    const messageEndRef = useRef(null);
    const divRef = useRef(null);
    const {state, dispatch} = useGlobalState();
    const {user} = state;
    const [messageText, setMessageText] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [activeUserDetails, setActiveUserDetails] = useState();
    const [messagesLoaded, setMessagesLoaded] = useState(false);
    const [attachments, setAttachments] = useState([]);
    const [attachmentLinks, setAttachmentLinks] = useState([]);
    const [isUploading, setIsUploading] = useState(false);
    const [imagePreviewOpen, setImagePreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState(false);
    const [imageError, setImageError] = useState('');
    useEffect(() => {
        setMessageText('');
        setAttachmentLinks([]);
        setAttachments([]);
        setImageError("");
        setIsLoading(true);
        autoScroll();
        if (activeChannel) {
            setActiveUserDetails(null);
            getActiveUserDetails();
            fetchMessages();
        }
    }, [activeChannel, messagesLoaded]);

    const fetchMessages = async () => {
        const result = await getMessagesByChannel(activeChannel.id);
        setActiveMessages(result);
        getConversations();
        setIsLoading(false);
    };

    useEffect(() => {
        autoScroll();
        scrollToEnd();
    }, [activeMessages, isLoading]);

    const scrollToEnd = () => {
        if (divRef.current) {
            divRef.current.scrollTop = divRef.current.scrollHeight;
        }
    };

    const autoScroll = () => {
        setMessagesLoaded(true);
        messageEndRef.current?.scrollIntoView({behavior: 'smooth'});
    };

    const getActiveUserDetails = async () => {
        try {
            const otherUser =
                (await activeChannel.participants[0]) === user.id
                    ? activeChannel.participants[1]
                    : activeChannel.participants[0];
            const {data, status} = await Instance.get(`users/${otherUser}`);
            if (status !== 200) return;
            setActiveUserDetails(data);
        } catch (e) {
            // console.log(e)
        }
    };

    const sendMessage = async () => {
        const message = {
            text: messageText,
            userId: user?.id,
            metadata: {},
            receiverId: activeUserDetails?.id,
            user: {
                username: user?.firstName,
            },
            images: attachmentLinks?.map(dt => dt.imageKey),
            channelId: activeChannel?.id,
        };
        socket.emit('chat', message);

        setChannels(prev =>
            prev.map(dt => {
                if (dt?.id === activeChannel?.id) {
                    return {
                        ...dt,
                        lastMessage: message,
                    };
                }
                return dt;
            }),
        );
        setMessageText('');
        setAttachmentLinks([]);
        setAttachments([]);
        setImageError("")
    };

    const handleSubmit = async e => {
        if (messageText === '' && attachmentLinks?.length === 0) return;
        e.preventDefault();
        await sendMessage();
    };

    const handleChange = e => {
        setMessageText(e.target.value);
        if(imageError){
            setImageError("")
        }
    };

    const handleFileUpload = async ({target}) => {
        try {
            setIsUploading(true);
            const files = target.files;
            if (files.length > 4 || attachments?.length + files.length > 4) {
                setImageError('You can only upload up to 4 images !');
                target.value = ''; // Reset the input to allow re-upload
                return;
            } else {
                setImageError('');
            }
            if (!files.length) return;
            const fileLinks = await FileService.uploadMultipleImages(files);
            if (!fileLinks) return;
            let newPictures = attachments.map(picture => picture);
            let newPicturesLinks = attachmentLinks?.map(picture => picture);

            for (let i = 0; i < files.length; i++) {
                newPictures.push({
                    preview: URL.createObjectURL(files[i]),
                    raw: files[i],
                    id: uuidv4(),
                });
            }
            newPicturesLinks.push(...fileLinks);
            setAttachmentLinks(newPicturesLinks);
            setAttachments(newPictures);
        } catch (error) {
            setIsUploading(false);
            // console.log(error.response)
        } finally {
            setIsUploading(false);
        }
    };

    const removeImage = (id, index) => {
        setAttachmentLinks(prev => prev.filter(img => img.id !== id));
        setAttachments(prev => prev.filter((img, idx) => idx !== index));
        setImageError("");
    };

    const renderItemImages = images => {
        return images.map((image, index) => (
            <>
                <div className='attchments-upload_item_image_box' key={index}>
                    <IconButton
                        aria-label='delete'
                        className={'remove-attachment'}
                        onClick={() => removeImage(image.id, index)}
                    >
                        <RemoveIcon className={'classes.icon'} />
                    </IconButton>
                    <img
                        src={image.preview ?? getImage(image.imageKey)}
                        alt=''
                        className='attchments-upload_item_image'
                    />
                </div>
            </>
        ));
    };

    const handlePreview = images => {
        if (images?.length > 0) {
            setImagePreviewOpen(true);
            setPreviewImage(images);
        }
    };
    const renderMessages = () => {
        return activeMessages.map((message, index) => {
            if (message.metadata?.enquiry) {
                return <EnquiryMessage messageObj={message} key={index} />;
            }
            return message.userId === user.id ? (
                <div key={index}>
                    <SentMessage
                        message={message}
                        handlePreview={handlePreview}
                    />
                </div>
            ) : (
                <div key={index}>
                    <ReceivedMessage
                        user={activeUserDetails}
                        message={message}
                        handlePreview={handlePreview}
                    />
                </div>
            );
        });
    };
    return (
        <div className='ActiveChatContainer'>
            {!isLoading && (
                <div className='ActiveChatHeader'>
                    {isMobile && (
                        <Arrow
                            onClick={() => setActiveChannel(null)}
                            rotation={180}
                            width={40}
                            height={40}
                        />
                    )}
                    <div className='ActiveChatHeaderUser'>
                        <Avatar
                            src={
                                activeUserDetails && activeUserDetails.avatar
                                    ? getImage(activeUserDetails.avatar)
                                    : MissingProfile
                            }
                        />
                        <span className='ActiveChatHeaderText'>
                            {activeUserDetails?.firstName +
                                ' ' +
                                activeUserDetails?.lastName}
                        </span>
                    </div>

                    <div className='ActiveChatHeaderRating'>
                        <span>
                            {activeUserDetails &&
                                activeUserDetails.lenderRating}
                            /5
                        </span>
                        <StarFilled fill='#e9d8b4' className='StarIconRating' />
                    </div>
                </div>
            )}

            <div
                ref={divRef}
                style={
                    isLoading
                        ? {justifyContent: 'center', alignItems: 'center'}
                        : null
                }
                className='ActiveChatMessageContainer'
            >
                {isLoading ? (
                    <CircularProgress size={30} color={'secondary'} />
                ) : (
                    activeMessages?.length > 0 && renderMessages()
                )}
            </div>

            <div className='ActiveChatInputContainer'>
                {attachments?.length > 0 && (
                    <div className='upload-files'>
                        {renderItemImages(attachments)}
                        {isUploading && (
                            <div className='attchments-upload_item_image_box'>
                                <CircularProgress
                                    size={30}
                                    color={'secondary'}
                                />
                            </div>
                        )}
                    </div>
                )}
                {isUploading && attachments?.length === 0 && (
                    <div className='upload-files'>
                        <div className='attchments-upload_item_image_box'>
                            <CircularProgress size={30} color={'secondary'} />
                        </div>
                    </div>
                )}

                {imageError && (
                    <div className='image-error'>
                        <span>{imageError}</span>
                        <button onClick={()=>setImageError("")}>x</button>
                    </div>
                )}
                <div className='chat-box-input'>
                    <form onSubmit={handleSubmit} style={{width: '100%'}}>
                        <input
                            className='ActiveChatInput'
                            placeholder='Message'
                            onChange={handleChange}
                            value={messageText}
                        />
                    </form>
                    <label>
                        <input
                            type='file'
                            id='selectFile'
                            accept='image/*'
                            style={{display: 'none'}}
                            onChange={e => handleFileUpload(e)}
                            multiple
                        />
                        <div
                            className='ActiveChatButton'
                            onClick={e => {
                                e.preventDefault();
                                document.getElementById('selectFile').click();
                            }}
                        >
                            <AttachFileIcon style={{color: 'white'}} />
                        </div>
                    </label>
                    <div className='ActiveChatButton' onClick={handleSubmit}>
                        <ArrowUpwardIcon style={{color: 'white'}} />
                    </div>
                </div>
                {imagePreviewOpen && previewImage?.length > 0 && (
                    <ImagesPreviewModal
                        setModal={setImagePreviewOpen}
                        images={previewImage}
                        modal={imagePreviewOpen}
                    />
                )}
            </div>
        </div>
    );
}
