import {useState, useEffect} from 'react';
import './messages.css';
import PageWrapper from '../../components/pageWrapper/pageWrapper';
import UserShedNav from '../../components/UserShedNav/UserShedNav';
import ActiveChat from '../../components/messaging/activeChat/ActiveChat';
import {CircularProgress, ClickAwayListener} from '@material-ui/core';
import useGlobalState from '../../util/useGlobalState';
import {isMobile} from 'react-device-detect';
import NoContent from '../../components/NoContent/NoContent';
import {useHistory } from 'react-router';
import { getChannelsByUser } from '../../services/channel';
import UserCard from '../../components/messaging/userCard/UserCard';
import socket from '../../socket';

export const Messages = () => {
    const {state} = useGlobalState();
    const {user } = state;
    const history = useHistory();
    const [accountContent, setAccountContent] = useState('Messages');
    const [activeMessages, setActiveMessages] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [activeChannel, setActiveChannel] = useState();
    const [popupOpen, setPopupOpen] = useState(false);
    const [channels, setChannels] = useState([]);
    const [conversationExist , setConversationExist] = useState(false)

    useEffect(() => {
        setIsLoading(true);
        if(user?.id){
            fetchChannels();
        }
    }, [user?.id]);


    useEffect(() => {
        socket.on('chat', (data) => {
          if (data.channelId === activeChannel?.id) {  
            setActiveMessages((prev) => [...prev, data]);
            if(data?.userId !== user?.id){
               socket.emit('markAsRead', { messageId: data.id });
            }
          }
          setChannels((prev)=>
            prev?.map((dt)=>{
                if(dt?.id === data?.channelId){
                   return {
                       ...dt,
                       lastMessage : data,
                   } 
                }
               return dt
            })
        )
        });
    
        return () => {
          socket.off('chat');
          socket.removeListener('chat')
        }
      }, [activeChannel?.id]);

    const fetchChannels = async () => {
        const result = await getChannelsByUser(user?.id);
        let data = result.channels?.find((dt)=> dt.lastMessage !== null)
        setConversationExist(data ? true : false)
        setChannels(result.channels);
        setIsLoading(false);
    };

    const renderCards = () => {
        return channels?.map((channel, index) => {
            if(channel.lastMessage){
                return (
                    <UserCard
                        key={index}
                        channel={channel}
                        activeChannel={activeChannel}
                        popupOpen={popupOpen}
                        setActiveChannel={setActiveChannel}
                        setPopupOpen={setPopupOpen}
                    />
                );
            }
        });
    };

    return (
        <PageWrapper>
            <ClickAwayListener onClickAway={() => setPopupOpen(false)}>
                <div className='UserShedWrapper'>
                    {!isMobile && (
                        <UserShedNav
                            setAccountContent={setAccountContent}
                            accountContent={accountContent}
                        />
                    )}
                    <div
                        className='ContentContainer'
                        style={
                            isLoading
                                ? {
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                  }
                                : null
                        }
                    >
                        {isLoading ? (
                            <CircularProgress color={'secondary'} />
                        ) : !conversationExist ? (
                            <NoContent
                                header='No Active Conversations'
                                text='You currently do not have any active conversations, borrow or lend an item to start messaging other users'
                                buttonText='Search for an item'
                                onButtonClick={() => history.push('/search')}
                            />
                        ) : (
                            <>
                                <div className='UserCardContainer'>
                                    <div className='MessagesHeading'>
                                        Messages
                                    </div>
                                    <div className='sidebar-cards'>
                                        {renderCards()}
                                    </div>
                                </div>
                                {activeChannel && (
                                    <ActiveChat
                                        activeChannel={activeChannel}
                                        setActiveChannel={setActiveChannel}
                                        activeMessages={activeMessages}
                                        setActiveMessages={setActiveMessages}
                                        setChannels={setChannels}
                                        getConversations={fetchChannels}
                                    />
                                )}
                            </>
                        )}
                    </div>
                </div>
            </ClickAwayListener>
        </PageWrapper>
    );
};

export default Messages;
