import {Avatar} from '@material-ui/core';
import React from 'react';
import getImage from '../../../util/getImage';
import './ReceivedMessage.css';
import MissingProfile from '../../../assets/Icons/MissingProfileIcon.png';
import GreyTriangle from '../../../assets/Icons/GreyTriangle';
import moment from 'moment';

export default function ReceivedMessage({user, message, handlePreview}) {
    const renderImages = images => {
        return images?.map((image, index) => (
            <>
                <div
                    className='attchments-upload_item_image_box'
                    onClick={() => handlePreview(images)}
                    key={index}
                >
                    <img
                        src={getImage(image)}
                        alt=''
                        className='attchments-upload_item_image'
                    />
                </div>
            </>
        ));
    };
    
    const showTimestamp =(date)=>{
        const isToday = moment(date).isSame(moment(), 'day');

       return  isToday ? moment(date).format('LT'): moment(date).format('lll');
    }
    return (
        <div className='ReceivedMessageContainer'>
            <div className='ReceivedMessageImageContainer'>
                <Avatar
                    src={
                        user && user.avatar
                            ? getImage(user.avatar)
                            : MissingProfile
                    }
                    className='ReceivedMessageImage'
                />
            </div>
            <div>
                {message.images?.length > 0 && (
                    <>
                        <div className='flex gap-2 mb-[3px]'>
                            {renderImages(message.images)}
                        </div>
                        <p className='receive-message-timestamp'>
                            {showTimestamp(message.createdAt)}
                        </p>
                    </>
                )}
                {message.text && (
                    <>
                        {' '}
                        <div className='ReceivedMessageTextContainer'>
                            <p>{message.text}</p>
                            <GreyTriangle />
                        </div>
                        <p className='receive-message-timestamp'>
                            {showTimestamp(message.createdAt)}
                        </p>
                    </>
                )}
            </div>
        </div>
    );
}
