import axios from 'axios';
import {useEffect, useState} from 'react';
import {BsFacebook, BsLinkedin} from 'react-icons/bs';
import {FiInstagram} from 'react-icons/fi';
import {Link, useHistory} from 'react-router-dom';
import {validate} from 'validate.js';
import {
    EmailArrowBtn,
    LBSBlackLogo,
} from '../../../assets/Images/Marketings/Marketings';
import Instance from '../../../util/axios';
import {contactUsConstraints} from '../../../util/validationConstraints';
import ValidationTextInput from '../../FormComponents/ValidationTextInput';
import FooterCopyRightComonent from '../../footer/footerCopyRightComonent';
import FooterDownloadComponent from '../../footer/footerDownloadComponent';
import './Footer.css';
import useErrorState from '../../../util/reducers/errorContext';
import {SNACKBAR_BUTTON_TYPES} from '../../../assets/Data/LBSEnum';
import {MESSAGES} from '../../../util/Message';

const Footer = () => {
    const [email, setEmail] = useState('');
    const [errorMessages, setErrorMessages] = useState({});
    const history = useHistory();
    const {errorDispatch} = useErrorState();

    useEffect(() => {
        if (Object.keys(errorMessages).length > 0) {
            const valid = validateInputs();
            if (valid) {
                setErrorMessages({});
                return;
            }
        }
    }, [email]);

    const getErrorMessage = inputName => {
        if (Object.keys(errorMessages).length === 0) return null;
        for (const key in errorMessages) {
            if (Object.keys(errorMessages)[0] === inputName)
                return errorMessages[key][0];
        }
    };
    const validateInputs = () => {
        const validationErrors = validate({email}, contactUsConstraints);
        if (validationErrors) {
            setErrorMessages(validationErrors);
            return false;
        }
        setErrorMessages({});
        return true;
    };

    const notify = message => {
        errorDispatch({
            type: 'openSnackBar',
            data: {
                message,
                btnText: SNACKBAR_BUTTON_TYPES.CLOSE,
                btnFunc: () => errorDispatch({type: 'closeSnackBar'}),
            },
        });
    };

    const sendSubscribeEmail = async () => {
        try {
            const valid = validateInputs();
            if (valid) {
                const result = await Instance.post('/news-latter/subscribe', {
                    email,
                    isSubscribe: true,
                });
                if (result.status !== 201) {
                    throw Error;
                }

                setEmail('');
                notify(MESSAGES.SUCCESS_NEWS_LETTER);
            }
        } catch (error) {
            if (error && axios.isAxiosError(error)) {
                if (
                    error?.code === 'ERR_NETWORK' ||
                    error?.code === 'ECONNABORTED'
                )
                    throw Error(
                        'There was an error with your connection, please try again',
                    );
            }
            notify(MESSAGES.ERROR_NEWS_LETTER);
        }
    };
    const lendingRoute = path => {
        window.location.href = `${process.env.REACT_APP_LENDING_PAGE}${path}`;
    };
    return (
        <div className='footer_container'>
            <FooterDownloadComponent />
            <div className='footer_content_container'>
                <div className='footer_content_box'>
                    <img
                        src={LBSBlackLogo}
                        alt='lbs footer logo'
                        className='footer_middle_logo pb-2'
                    />
                    <p className='footer_first_title'>Share stuff, do good</p>
                    <p className='footer_first_desc'>
                        Buy less, lend and borrow more. Unlock the power of
                        sharing to make, mend, learn and care for your place and
                        our planet together.
                        <br />
                        <br />
                    </p>
                    <p className='footer_first_desc'>
                        <a href='mailto:support@littlebigshed.co.nz'>
                            support@littlebigshed.co.nz
                        </a>
                    </p>
                </div>
                <div className='footer_content_box'>
                    <p
                        className='footer_section_title_main pb-2'
                        onClick={() => {
                            lendingRoute('/about_us');
                        }}
                    >
                        About Us
                    </p>

                    <Link className='footer_section_title' to='/privacy_policy'>
                        Privacy Policy
                    </Link>
                    <Link
                        className='footer_section_title'
                        to='/rental_agreement'
                    >
                        Rental Agreement
                    </Link>
                    <Link
                        className='footer_section_title'
                        to='/cancellation_policy'
                    >
                        Cancellation Policy
                    </Link>
                    <Link
                        className='footer_section_title'
                        to='/damages_and_disputes'
                    >
                        Damages And Disputes
                    </Link>
                </div>
                <div className='footer_content_box'>
                    <p
                        className='footer_section_title_main pb-2'
                        onClick={() => lendingRoute('/howitworks')}
                    >
                        How It Works
                    </p>
                    <p
                        className='footer_section_title'
                        onClick={() => lendingRoute('/lender_protection')}
                    >
                        Lender protection (insurance policy)
                    </p>
                    <p
                        className='footer_section_title'
                        onClick={() => lendingRoute('/faqs')}
                    >
                        FAQs for sharing
                    </p>
                    <p
                        className='footer_section_title'
                        onClick={() => history.push('/login')}
                    >
                        Sign Up / Login
                    </p>
                </div>
                <div className='footer_content_box'>
                    <p
                        className='footer_section_title_main pb-2'
                        onClick={() => lendingRoute('/')}
                    >
                        Top Categories
                    </p>
                    <Link
                        className='footer_section_title'
                        to={`/search?category=${encodeURIComponent(
                            'DIY & Garden',
                        )}`}
                    >
                        Tools & Garden
                    </Link>
                    <Link
                        className='footer_section_title'
                        to={`/search?category=${encodeURIComponent(
                            'Photography',
                        )}`}
                    >
                        Photography
                    </Link>
                    <Link
                        className='footer_section_title'
                        to={`/search?category=${encodeURIComponent(
                            'Parties & Events',
                        )}`}
                    >
                        Events & Parties
                    </Link>
                    <Link
                        className='footer_section_title'
                        to={'/search/?keyword=&category=Other'}
                    >
                        Hobbies
                    </Link>
                    <Link
                        className='footer_section_title'
                        to={`/search?category=${encodeURIComponent(
                            'Outdoor & Sport',
                        )}`}
                    >
                        Outdoor
                    </Link>
                    <Link
                        className='footer_section_title'
                        to={`/search?category=${encodeURIComponent(
                            'Babies & Kids',
                        )}`}
                    >
                        Kids
                    </Link>
                    <Link
                        className='footer_section_title'
                        to={'/search/?keyword=&category=Vehicle'}
                    >
                        Vehicle
                    </Link>
                    <Link
                        className='footer_section_title'
                        to={'/search/?keyword=&category=Closet'}
                    >
                        Closet
                    </Link>
                    <Link
                        className='footer_section_title'
                        to={'/search/?keyword='}
                    >
                        Browse All
                    </Link>
                </div>
                <div className='footer_content_box'>
                    <p className='footer_last_title pb-2'>Stay Connected</p>
                    <p className='footer_last_desc pb-3'>
                        Subscribe to the Little Big Shed newsletter to keep up
                        to date with our sharing news and new item listings!
                    </p>
                    <div className='subscribe_email_container'>
                        <ValidationTextInput
                            placeholder='Email'
                            errorHeader='Invalid Email'
                            errorMessage={getErrorMessage('email')}
                            onChange={e => setEmail(e.target.value.trim())}
                            value={email}
                        />
                        <button
                            className='subscribe_button'
                            onClick={sendSubscribeEmail}
                        >
                            <img
                                src={EmailArrowBtn}
                                alt='email button'
                                className='email_arrow_icon'
                            />
                        </button>
                    </div>
                    <p className='footer_last_title margin_top pb-2'>
                        Get Social
                    </p>
                    <div className='flex_box'>
                        <a href='https://www.instagram.com/share_on_littlebigshed'>
                            <FiInstagram className='social_media_icons bigger_icon' />
                        </a>
                        <a href='https://www.linkedin.com/company/little-big-shed/'>
                            <BsLinkedin className='social_media_icons bigger_icon' />
                        </a>
                        <a href='https://www.facebook.com/LittleBigShed'>
                            <BsFacebook className='social_media_icons bigger_icon' />
                        </a>
                    </div>
                </div>
            </div>
            <FooterCopyRightComonent />
        </div>
    );
};

export default Footer;
