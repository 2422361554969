import { Channel } from '../types/Chat';
import Instance from '../util/axios';

export const getChannel = async (id: string) => {
    const { data } = await Instance.get(`/channels/${id}`);

    const participants:string[]=[];

    if(data.name){
        await data.participants.forEach((participant:any)=>{
            participants.push(participant.id);
        });
    }

    return {
        channel:data,
        participants
    };
}

export const getChannelsByUser = async (id: string) => {
    const { data } = await Instance.get(`/channels/user/${id}`);
    return data;
}

export const createChannel = async (channel:any) => {
    const { data } = await Instance.post('/channels', channel);
    return data;
}

export const updateChannel = async (id: string, channel: any) => {
    const { data } = await Instance.put(`/channels/${id}`, channel);
    return data;
}

export const deleteChannel = async (id: string) => {
    const { data } = await Instance.delete(`/channels/${id}`);
    return data;
}